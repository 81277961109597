export const GET_NEWSLETTERS_STATED = "GET_NEWSLETTERS_STATED";
export const GET_NEWSLETTERS = "GET_NEWSLETTERS";
export const GET_NEWSLETTERS_ENDED = "GET_NEWSLETTERS_ENDED";
export const ADD_NEWSLETTER_STATED = "ADD_NEWSLETTER_STARTED";
export const ADD_NEWSLETTER = "ADD_NEWSLETTER";
export const ADD_NEWSLETTER_ENDED = "ADD_NEWSLETTER_ENDED";
export const EDIT_NEWSLETTER_STATED = "EDIT_NEWSLETTER_STATED";
export const EDIT_NEWSLETTER = "EDIT_NEWSLETTER";
export const EDIT_NEWSLETTER_ENDED = "EDIT_NEWSLETTER_ENDED";
export const GET_NEWSLETTER = "GET_NEWSLETTER";
export const GET_NEWSLETTER_STATED = "GET_NEWSLETTER_STATED";
export const GET_NEWSLETTER_ENDED = "GET_NEWSLETTER_ENDED";
export const RESET_NEWSLETTER = "RESET_NEWSLETTER";
export const ERROR_NEWSLETTER = "ERROR_NEWSLETTER";
export const GET_ALL_NEWSLETTERS_STATED = "GET_ALL_NEWSLETTERS_STATED";
export const GET_ALL_NEWSLETTERS = "GET_ALL_NEWSLETTERS";
export const GET_ALL_NEWSLETTERS_ENDED = "GET_ALL_NEWSLETTERS_ENDED";
